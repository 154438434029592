<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE ****** WARNING!!!!-->
<template>
    <vue-draggable-resizable @activated="onActivated" :active="true" :parent="parent" :w="w" :h="h" :x="x" :y="y"
        :min-height="250" :z="99999" class-name="vdr-custom" :prevent-deactivation="preventDeactivation"
        :draggable="draggable" drag-handle=".header" :resizable="false">
        <v-row no-gutters class="drag-wrapper">
            <v-col rounded class="header">
                <HbIcon class="drag-icon" mdi-code="mdi-drag-custom" color="white" />
                <span class="text-heading">Merge Fields</span>
                <div class="close-icon" @click="onCloseClick">
                    <HbIcon mdi-code="mdi-close" color="white" />
                </div>
            </v-col>
            <v-col class="content">
                <div>
                    <div class="pa-5">
                        <HbSelect box v-model="selectCategory"
                            :items="['All Merge Fields', 'Tenant', 'Facility', 'Space', 'Reversal Space']"
                            :clearable="false" v-validate="'required|max:45'" data-vv-scope="default"
                            data-vv-name="phone" data-vv-as="Phone" :error="errors.has('default.phone')" />
                        <HbTextField class="mt-3" search v-model="mergeSearch" v-validate="'required|max:45'"
                            data-vv-scope="default" data-vv-name="text-field-search" data-vv-as="Text Field Search"
                            @click:clear="clearSearch" :error="errors.has('default.text-field-search')"
                            placeholder="Search Merge Fields" />
                    </div>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <ul class="list-view">
                        <!-- Iterate over the groups -->
                        <li v-for="(fields, category) in filteredFields" :key="category">
                            <!-- Render category heading -->
                            <h3 class="mx-6 mt-2 category">{{ category }}</h3>
                            <!-- Iterate over fields within each category -->
                            <ul class="ul-list" v-if="fields">
                                <li v-for="(field, index) in fields" :key="index" class="drag-item"
                                    @click="addMergeField(field.token)">
                                    <div class="mt-3 mb-3 hb-text-light">{{
                                        field.token }}</div>
                                    <div class="tooltip-label">
                                        <hb-tooltip>
                                            <template v-slot:item>
                                                <div class="plus-icon">
                                                    <HbIcon mdi-code="mdi-information" small />
                                                </div>
                                            </template>
                                            <template v-slot:body>
                                                {{ field.label }}
                                            </template>
                                        </hb-tooltip>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </v-col>
        </v-row>
    </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable';
import { MergeFields } from './MergeFields';

export default {
    props: {
        className: {
            type: String,
            default: 'vdr', // Default class name if not provided
        },
        preventDeactivation: {
            type: Boolean,
            default: false,
        },
        draggable: {
            type: Boolean,
            default: true,
        },
        parent: {
            type: Boolean,
            default: true
        },
        w: {
            type: Number,
            default: 300,
        },
        h: {
            type: Number,
            default: 300,
        },
        x: {
            type: Number,
            default: 950
        },
        y: {
            type: Number,
            default: 50
        },
        context: {
            type: String,
        }
    },
    components: {
        VueDraggableResizable,
    },
    data() {
        return {
            selectCategory: 'All Merge Fields',
            mergeSearch: "",
            mergeFields: MergeFields.mergeFields,
        };
    },
    watch: {
        x(newX) {
            this.x = newX;
        },
        y(newY) {
            this.y = newY;
        },
    },
    computed: {
        groupedFields() {
            const grouped = {};
            // Group mergeFields by category
            this.mergeFields.forEach(field => {
                const category = field.token.split('.')[0].substring(1);
                if (!grouped[category]) {
                    grouped[category] = [];
                }
                grouped[category].push(field);
            });
            return grouped;
        },
        filteredFields() {
            const filtered = {};
            const searchQuery = (this.mergeSearch || '').trim().toLowerCase();
            const categoryFilter = this.selectCategory.replace(/\s+/g, '');
            Object.keys(this.groupedFields).forEach(category => {
                if (categoryFilter === 'AllMergeFields' || category === categoryFilter) {
                    this.groupedFields[category].forEach(field => {
                        if (field.token.toLowerCase().includes(searchQuery)) {
                            if (!filtered[category]) {
                                filtered[category] = [];
                            }
                            filtered[category].push(field);
                        }
                    });
                }
            });
            return filtered;
        }
    },
    methods: {
        clearSearch() {
            this.mergeSearch = '';
        },
        addMergeField(token) {
            if (this.context === 'email') {
                this.$emit('addMergeField', token, this.context) // emit to parent 
            }
            else if (this.context === 'sms') {
                this.$emit('addMergeField', token, this.context) // emit to parent 
            }
        },
        onCloseClick() {
            // Handle close icon click
            this.$emit('onCloseClick');
        },


        // native functions
        onActivated() {
            //console.log('Activated');
        },
    },
};
</script>

<style scoped>
.ul-list {
    padding: 0 !important;
}

.plus-icon {
    cursor: pointer;
}

.plus-icon>span>span>i {
    font-size: 18px !important;
}

.content-class {
    background: rgba(250, 251, 252, 1);
}

.list-view {
    height: 300px;
    list-style: none;
    /* Set a fixed height for the scrollable container */
    overflow-y: auto;
    overflow-x: hidden;
    padding: inherit;
    /* Enable vertical scrolling */
}

.category {
    color: var(--Primary-Brand-Darker, #003135);
    font-family: Graphik;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.section-content {
    height: max-content;
    margin-bottom: 49px !important;
}

.drag-item {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    cursor: pointer;
    padding-left: 24px;
}

:hover.drag-item {
    background: var(--Primary-Cloud-Lighter, #F9FAFB);
}

:active.drag-item {
    background: var(--Primary-Brand-Lighter, #E0F5F5);
}

.drag-icon {
    padding: 8px 0px 8px 12px;
}

.tooltip-label {
    padding-right: 14px;

}

.vdr-custom {
    position: absolute;
}

.drag-wrapper {
    border: 3px solid;
    border-color: var(--Primary-Brand-Darker, #003135);
    border-radius: 10px;
    overflow: hidden;
}

.content {
    background-color: #FFFFFF;
    margin-top: 40px;
}

.header {
    border-radius: 8px 8px 0px 0px;
    height: 40px;
    /* Fixed header height */
    background: var(--Primary-Brand-Darker, #003135);
    cursor: move;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    cursor: all-scroll;
}

.text-heading {
    color: #ffffff;
}

.close-icon {
    cursor: pointer;
    padding: 8px 12px 8px 0px;
}
</style>